@font-face {
    font-family: "Inter";
    src: url("/Inter.ttf");
}

body {
    margin: 0px;
}

.App {
    margin: 0 auto;
    font-family: Inter, Helvetica, arial, sans-serif;
}

.AppHeader {
    color: #ffffff;
    background: #2952be;
    width: 100%;
    height: 47px;
    left: 0px;
    top: 0px;
}

.AppMain {
    margin: 0px auto;
    max-width: 960px;
}

.topRightNav {
    margin-right: 16px;
    float: right;
    transform: translate(0%, 50%);
    min-width: 0;
    max-width: 40%;
    display: flex;
}

.spacer {
    margin-left: 8px;
}

.title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #ffffff;
    margin-left: 8px;
}

.titleAndLogo {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 23px; /* AppHeader.height / 2 */
}

.lightButton {
    background: #ffffff;
    border-radius: 6px;
    border: none;
    font-family: Inter;
    color: #2952be;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 4px 8px 4px 8px;
}

.darkButton {
    background: #2952be;
    border-radius: 6px;
    border: none;
    font-family: Inter;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 4px 8px 4px 8px;
}

button:hover {
    cursor: pointer;
}

.loggedInUser {
    margin-right: 16px;
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.inputLogin {
    background: #ffffff;
    border: 0px;
    border-radius: 200px;
    padding-left: 16px;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
    height: 36px;
}
.inputLogin:focus {
    outline: none;
}

.modalSignIn {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #2952be;
    margin-bottom: 16px;
}

.modalSignInButton {
    margin-top: 20px;
    float: right;
    cursor: pointer;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #f7f7f7;
    border-radius: 12px;
    z-index: 1010;
    width: 640px;
    max-width: 100%;
    height: auto;
    padding: 24px;
}

.modal-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.closeButton {
    cursor: pointer;
    margin: 8px;
    float: right;
}

.filterButton {
    margin-right: 20px;
    vertical-align: middle;
    cursor: pointer;
}

.errblock {
    background-color: lightcoral;
    padding: 15px;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 2em;
    height: 2em;
}
.loader {
    margin: 20px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.5em solid rgba(41, 82, 190, 1);
    border-right: 0.5em solid rgba(41, 82, 190, 1);
    border-bottom: 0.5em solid rgba(41, 82, 190, 1);
    border-left: 0.5em solid #f7f7f7;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 960px) {
    .titleAndLogo {
        margin-left: 8px;
        margin-right: 8px;
        left: 0%;
        transform: translate(0%, -50%);
    }
    .topRightNav {
        max-width: 60%;
    }
}

#recaptchaguest {
    margin: 0 auto;
    display: table;
    padding: 10px;
}
