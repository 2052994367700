.ReputationPane{
    position: fixed;
    top: 47px;
    right: 0%;
    margin-right: 12px;
    margin-top: 12px;
    width: 640px;
    max-width: 100vw;
    background: #F7F7F7;
    box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding-left: 24px;
    padding-bottom: 24px;
    box-sizing: border-box;
}

.repTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    /* identical to box height */

    /* Light / Primary content */
    color: #17191C;
    margin-top: 24px;
}

.repDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;

    /* identical to box height, or 160% */

    /* Light / Primary content */
    color: #17191C;
    margin-top: 12px;
    margin-bottom: 12px;
}

.addFilter {
    margin-top: 8px;
    margin-bottom: 8px;
    background: #8D99A5;
    border-radius: 6px;
    border: none;
    font-family: Inter;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 4px 8px 4px 8px;
    cursor: pointer;
}

.saveChanges {
    float: right;
    margin-right: 16px;
}

.cancelButton{
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
    background: #8D99A5;
    border-radius: 6px;
    border: none;
    font-family: Inter;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 4px 8px 4px 8px;
    cursor: pointer;
}

.listTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    /* Light / Primary content */
    color: #17191C;
}

.listEntry {
    border-top: 1px solid #E3E8F0;
    margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listEntryBottom {
    border-bottom: 1px solid #E3E8F0;
    margin-bottom: 16px;
}

.listDelete {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 16px;
    cursor: pointer;
}

.listEntryLeft{
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.listEntryRight{
}

.range {
    width: 224px;
    max-width: 100%;
}

.rangeLabels{
    display: flex;
    justify-content: space-between;
}

.rangeLabel {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

}