.errblock {
    background-color: lightcoral;
    padding: 15px;
}

.UserPageHeader{
    line-height: 18px;
    padding-bottom: 15px;
    font-family: Inter;
    background: #F7F7F7;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
}

.UserProfile {
    display: grid;
    grid-template-columns: 1fr 10fr;
    column-gap: 10px;
    margin-bottom: 10px;
}

.UserPage{
    margin-top: 16px;
}

.UserPageBody{
    background: #F7F7F7;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.displayName{
    /* Body SemiBold */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    /* Light / Primary content */
    color: #17191C;

    margin-bottom: 8px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.userName{
    /* Body SemiBold */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    /* Light / Primary content */
    color: #17191C;

    margin-bottom: 8px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tabGroup{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.tabSelected {
    background: #F7F7F7;
    border-radius: 12px 12px 0px 0px;
}

.tab{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;

    color: #2952BE;
    flex-grow: 1;
    cursor: pointer;
}

.emptyList {
    padding: 8px;
    text-align: center;
}

.userAvatar{
    margin-right: 1em;
    max-width: 64px;
    max-height: 64px;
}

.userSection {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}