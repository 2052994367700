.child{
    border-left: 1px solid #8D99A5;
    padding-left: 8px;
}

.verticalChild{
    display: flex;
}

.threadCorner{
    float: left;
    position: absolute;
    margin-top: -1px;  /* so border lines merge in correctly */
}

.threadFork{
    float: left;
    position: absolute;
    margin-top: 18px;  /* so border lines merge in correctly */
}

.threadLine{
    border-left: 1px solid #8D99A5;
    margin-left: 25px;
}

.threadLineHolder{
    display: flex;
}

.messageHolder{
    min-width: 0;
    flex-grow: 2;
}

.blankThreadLine{
    margin-left: 25px;
}

.StatusPage{
    background: #F7F7F7;
    border-radius: 12px 12px 0px 0px;
    padding: 20px;
}

.StatusMessage {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}

.BackButton{
    /* Light / Element */
    cursor: pointer;
}

.statusButtons{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.viewButtonWrapper{
    border: 1px solid #8D99A5;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 2px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    user-select: none;
}
.viewButton{
    color: #737D8C;
    background: #FFFFFF;
    border-radius: 0px;
    border: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 4px 8px 4px 8px;
}