.MessageHeader{
    color: darkgray;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
}

.Message{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.MessageBody{
    margin-left: 24px;
    margin-top: 6px;
    margin-bottom: 8px;
    min-width: 0;
}

.moreCommentsButton {
    background: rgba(41, 82, 190, 0.1);
    border-radius: 4px;
    color: #2952BE;
    margin-right: 8px;
}

.MessageAuthor {
    /* Caption SemiBold */
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    /* identical to box height */

    color: #2952BE;

    order: 1;

    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
}

.DateString {
    /* Micro */
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

    /* Light / Secondary content */
    color: #737D8C;

    order: 2;

    margin-left: 6px;
    box-sizing: border-box;
    cursor: pointer;
}

.MessageText {
    /* Body */
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    word-break: break-word;

    /* or 160% */
    cursor: pointer;

    color: #17191C;
}

.MessageButtons{
    margin-right: 12px;
}

.inputReply{
    background: #FFFFFF;
    border: 0px;
    border-radius: 200px;
    flex-grow:2;
    padding-left: 16px;
}
.inputReply:focus {
    outline: none;
}

.sendButton{
    margin: 8px;
}

.sendButtonActive{
    cursor: pointer;
}

.inputReplyWithButton{
    display:flex;
    flex-direction:row;
}

.userImage{
    max-width: 100%;
    margin: 5px;
    cursor: pointer;
}
